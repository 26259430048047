import * as React from "react"
import {Merged, Plane, useGLTF} from "@react-three/drei";
import {THEME} from "../ui/theme";
import { useControls } from "leva";
import {VideoScreen} from "./VideoScreen";
import {Canvas} from "@react-three/fiber";
import {Suspense, useEffect, useLayoutEffect, useMemo} from "react";
import {SkeletonUtils} from "three-stdlib";
import {degToRad} from "three/src/math/MathUtils";

const rockModelPath = '/models/scenery/rockLarge.glb'
const treeModelPath = '/models/scenery/tree_default_fall.glb'
const benchModelPath = '/models/scenery/bench.glb'
const fountainRoundPath = '/models/scenery/fountainRound.glb'
const woodPathPath = '/models/scenery/path_wood.glb'
const foodTruckPath = '/models/scenery/foodTruck.glb'
const grassPath = '/models/scenery/grass.glb'
const yellowFlowerPath = '/models/scenery/flower_yellowA.glb'
const puddlePath = '/models/scenery/snowPatch.glb'
const donutPath = '/models/scenery/donutSprinkles.glb'

const useEnableShadows = (scene: any, castShadow: boolean = true, receiveShadow: boolean = true) => {
    return useMemo(() => {
        scene.traverse(obj => {
            if (obj.isMesh) {
                obj.castShadow = castShadow
                obj.receiveShadow = receiveShadow
                obj.frustumCulled = false
            }
            if (obj.material) {
                obj.material.metalness = 0;
            }
        })
        const clonedScene = SkeletonUtils.clone(scene)
        return clonedScene
    }, [scene])
}

const useSetMaterialColor = (scene: any, materials: Record<string, string>, url?: string) => {
    useMemo(() => {
        scene.traverse(obj => {
            if (obj.material) {
                if (materials[obj.material.name]) {
                    obj.material.color.set(materials[obj.material.name]).convertSRGBToLinear()
                    obj.material.metalness = 0;
                }
            }
        })
    }, [scene, materials])
}

const grassColors = {
    grass: '#73a33a',
}

const Grass: React.FC = () => {
    const { nodes, scene } = useGLTF(grassPath) as any

    useEnableShadows(scene, true, false)

    const {
        grass,
    } = grassColors
    // } = useControls({
    //     grass: '#72ce48',
    // })

    useSetMaterialColor(scene, {
        grass,
    })

    return (
        <Merged meshes={nodes} castShadow>
            {
                ({grass: Grass}) => {
                    return (
                        <>
                            <Grass position={[6, 0, -8.6]}/>
                            <Grass position={[10, 0, 12]}/>
                            <Grass position={[2, 0, -10]}/>
                            <Grass position={[-1, 0, -9]}/>
                            <Grass position={[-5.75, 0, -7.5]}/>
                            <Grass position={[5, 0, 0]}/>
                            <Grass position={[8.5, 0, -1]}/>
                            <Grass position={[-8.5, 0, -1]}/>
                            <Grass position={[-6.75, 0, -3]}/>
                            <Grass position={[-12, 0, -7]}/>
                            <Grass position={[12, 0, -7]}/>
                        </>
                    )
                }
            }
        </Merged>
    )
}

const yellowFlowerColors = {
    grass: grassColors.grass,
    colorYellow: '#f5b800',
}

const Flowers: React.FC = () => {
    const { nodes, scene } = useGLTF(yellowFlowerPath) as any

    useEnableShadows(scene, true, false)

    // const colors = useControls(yellowFlowerColors)
    const colors = yellowFlowerColors

    // const {
    //     grass,
    // } = grassColors
    // // } = useControls({
    // //     grass: '#72ce48',
    // // })
    //
    useSetMaterialColor(scene, colors)

    const {
        Mesh_flower_yellowA: FlowerA,
        Mesh_flower_yellowA_1: FlowerB,
    } = nodes

    return (
        <Merged meshes={{
            FlowerA,
            FlowerB,
        }} castShadow>
            {
                ({FlowerA, FlowerB}) => {
                    return (
                        <>
                            <FlowerA position={[-6, 0, 2]} scale={[1.5, 1.5, 1.5]}/>
                            <FlowerB position={[-6, 0, 2]} scale={[1.5, 1.5, 1.5]}/>
                            <FlowerA position={[6.75, 0, 1.6]} scale={[1.5, 1.5, 1.5]}/>
                            <FlowerB position={[6.75, 0, 1.6]} scale={[1.5, 1.5, 1.5]}/>
                        </>
                    )
                }
            }
        </Merged>
    )
}

const rockColors = {
    stone: '#516768',
}

const Rocks: React.FC = () => {
    const { nodes, scene } = useGLTF(rockModelPath) as any

    useEnableShadows(scene)

    const {
        stone,
    } = rockColors
    // = useControls({
    //     stone: '#516768',
    // })

    useSetMaterialColor(scene, {
        stone,
    })

    return (
        <Merged meshes={nodes} castShadow>
            {
                ({rockLarge: RockLarge}) => {
                    return (
                        <>
                            {/*<RockLarge scale={[2, 2, 2]}/>*/}
                            <RockLarge position={[11, 0, -9.5]} scale={[0.5, 0.5, 0.5]}/>
                            <RockLarge position={[13, 0, -9]} scale={[0.5, 0.5, 0.5]}/>
                            <RockLarge position={[-9, 0, -9.5]} scale={[0.5, 0.5, 0.5]}/>
                            <RockLarge position={[-12.5, 0, -10]} scale={[0.5, 0.5, 0.5]}/>
                        </>
                    )
                }
            }
        </Merged>
    )
}

const pathColors = {
    dirt: '#765118',
    dirtDark: '#6b2918'
}

const WoodPath: React.FC = () => {
    const { nodes, scene } = useGLTF(woodPathPath) as any

    const {
        dirt,
        dirtDark
    } = pathColors
    // = useControls({
    //     dirt: '#55523c',
    // })

    useSetMaterialColor(scene, {
        dirt,
        dirtDark
    })

    const {
        Mesh_path_wood_1: Path,
    } = nodes

    return (
        <Merged meshes={{
            Path,
        }} key={dirt}>
            {
                ({Path}) => {
                    return (
                        <>
                            <Path position={[7.5, 0, 0]} rotation={[0, degToRad(110), 0]} scale={[3, 3, 3]}/>
                            <Path position={[9, 0, 3]} rotation={[0, degToRad(120), 0]} scale={[3, 3, 3]}/>
                            <Path position={[10.8, 0, 5.8]} rotation={[0, degToRad(125), 0]} scale={[3, 3, 3]}/>
                            <Path position={[12.5, 0, 8.8]} rotation={[0, degToRad(110), 0]} scale={[3, 3, 3]}/>
                            <Path position={[19, 0, 10.5]} rotation={[0, degToRad(180), 0]} scale={[3, 3, 3]}/>
                            <Path position={[22, 0, 10.5]} rotation={[0, degToRad(180), 0]} scale={[3, 3, 3]}/>
                            <Path position={[25, 0, 10.5]} rotation={[0, degToRad(180), 0]} scale={[3, 3, 3]}/>
                        </>
                    )
                }
            }
        </Merged>
    )
}

const Model: React.FC<any> = ({url, materials = {}, ...props}) => {
    const { scene } = useGLTF(url) as any

    const clonedScene = useEnableShadows(scene)

    useSetMaterialColor(scene, materials, url)

    return (
        <primitive {...props} object={clonedScene} dispose={null}/>
    )
}

const generalColors = {
    benchStone: '#505462',
    benchWoodDark: '#7c5649',
    benchWood: '#9a6c55',
    fountainStone: '#90aecc',
    fountainStoneDark: '#6188af',
    water: '#0976e3',
    woodBirch: '#6f5645',
    leafsFall: '#228c22',
    snow: '#02bad6',
    brownLight: '#f5a342',
    purpleLight: '#d855a8',
    orange: '#fa2909',
    yellow: '#e8df11',
    green: '#17814e'
}

const Assets: React.FC = () => {

    const {
        benchStone,
        benchWoodDark,
        benchWood,
        fountainStone,
        fountainStoneDark,
        water,
        woodBirch,
        leafsFall,
        snow,
        brownLight,
        purpleLight,
        orange,
        yellow,
        green
    } = generalColors
    // = useControls({
    //     benchStone: '#505462',
    //     benchWoodDark: '#7c5649',
    //     benchWood: '#9a6c55',
    //     fountainStone: '#90aecc',
    //     fountainStoneDark: '#6188af',
    //     water: '#0976e3',
    //     wood: '#6f5645',
    //     leaves: '#739a45',
    // })

    const treeMaterials = {
        woodBirch,
        leafsFall,
    }

    const donutMaterials = {
        brownLight,
        purpleLight,
        orange,
        yellow,
        green
    }

    return (
        <>
            <Suspense fallback={null}>
                <Grass/>
            </Suspense>
            <Suspense fallback={null}>
                <Flowers/>
            </Suspense>
            <Suspense fallback={null}>
                <Rocks/>
            </Suspense>
            <Suspense fallback={null}>
                <WoodPath/>
            </Suspense>
            <Suspense fallback={null}>
                <Model url={treeModelPath} materials={treeMaterials} position={[-10, 0, -7]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[-12, 0, -18]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[-14, 0, -9]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[9, 0, -8]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[13, 0, -10]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[16, 0, -20]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[19, 0, -12]} scale={[3, 3, 3]}/>
                <Model url={treeModelPath} materials={treeMaterials} position={[21, 0, -7]} scale={[3, 3, 3]}/>
            </Suspense>
            <Suspense fallback={null}>
                <Model url={benchModelPath} materials={{
                    stone: benchStone,
                    woodDark: benchWoodDark,
                    wood: benchWood,
                }} scale={[5, 5, 5]} position={[-6, 0, 10]} rotation={[0, degToRad(210), 0]}/>
            </Suspense>
            <Suspense fallback={null}>
                <Model url={fountainRoundPath} materials={{
                    stone: fountainStone,
                    stoneDark: fountainStoneDark,
                    water,
                }} position={[5.5, 0.05, 22]} scale={[2, 2, 2]}/>
            </Suspense>
            <Suspense fallback={null}>
                <Model url={foodTruckPath} position={[16, 0.5, 5]} rotation={[0, degToRad(-115), 0]} scale={[0.15, 0.15, 0.15]}/>
            </Suspense>
            <Suspense fallback={null}>
                <Model url={puddlePath} position={[0, 0.01, 17]} rotation={[0, degToRad(0), 0]} materials={{
                    snow: snow,
                }} scale={[3, 3, 3]}/>
            </Suspense>
            <Suspense fallback={null}>
                <Model url={donutPath} position={[-3.5, 0, 9.5]} rotation={[0, degToRad(0), 0]} materials={donutMaterials} scale={[4, 4, 4]}/>
            </Suspense>
        </>
    )
}

export const Scenery: React.FC<{
    htmlContainerRef: any,
    isDesktopSize: boolean,
}> = ({htmlContainerRef, isDesktopSize}) => {

    // const {
    //     color,
    // } = useControls({
    //     color: THEME.colors.green,
    // })


    return (
        <>
            <fog attach="fog" args={["#447c42", 50, 100]} />
            <ambientLight intensity={0.9} />
            <Plane args={[256, 256]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
                <meshStandardMaterial color={THEME.colors.green} />
            </Plane>
            <VideoScreen isDesktopSize={isDesktopSize} htmlContainerRef={htmlContainerRef}/>
            <Assets/>
        </>
    )
}

export type AnimalData = {
    key: string,
    name: string,
    idleEndFrame?: number,
    timeScale?: number,
}

const EXTENDED_TIME_SCALE = 4

export const ArcticFox: AnimalData = {
    key: 'ArcticFox',
    name: 'Arctic Fox',
    idleEndFrame: 15,
}

export const Buffalo: AnimalData = {
    key: 'Buffalo',
    name: 'Buffalo',
    idleEndFrame: 13,
}

export const Cat: AnimalData = {
    key: 'Cat',
    name: 'Cat',
    idleEndFrame: 15,
}

export const Cheetah: AnimalData = {
    key: 'Cheetah',
    name: 'Cheetah',
    idleEndFrame: 70,
    timeScale: EXTENDED_TIME_SCALE,
}

export const Chick: AnimalData = {
    key: 'Chick',
    name: 'Chick',
    idleEndFrame: 15,
}

export const Cow: AnimalData = {
    key: 'Cow',
    name: 'Cow',
    idleEndFrame: 13,
}

export const Crow: AnimalData = {
    key: 'Crow',
    name: 'Crow',
    idleEndFrame: 70,
    timeScale: EXTENDED_TIME_SCALE,
}

export const Dog: AnimalData = {
    key: 'Dog',
    name: 'Dog',
    idleEndFrame: 13,
}

export const Donkey: AnimalData = {
    key: 'Donkey',
    name: 'Donkey',
    idleEndFrame: 15,
}

export const Dove: AnimalData = {
    key: 'Dove',
    name: 'Dove',
    idleEndFrame: 15,
}

export const Duck: AnimalData = {
    key: 'Duck',
    name: 'Duck',
    idleEndFrame: 15,
}

export const Eagle: AnimalData = {
    key: 'Eagle',
    name: 'Eagle',
    idleEndFrame: 13,
}

export const Elephant: AnimalData = {
    key: 'Elephant',
    name: 'Elephant',
    idleEndFrame: 13,
}

export const Flamingo: AnimalData = {
    key: 'Flamingo',
    name: 'Flamingo',
    idleEndFrame: 70,
    timeScale: EXTENDED_TIME_SCALE,
}

export const Fox: AnimalData = {
    key: 'Fox',
    name: 'Fox',
    idleEndFrame: 15,
}

export const Gazelle: AnimalData = {
    key: 'Gazelle',
    name: 'Gazelle',
    idleEndFrame: 13,
}

export const Goldfish: AnimalData = {
    key: 'Goldfish',
    name: 'Goldfish',
    idleEndFrame: 15,
}

export const Hen: AnimalData = {
    key: 'Hen',
    name: 'Hen',
    idleEndFrame: 13,
}

export const Hippo: AnimalData = {
    key: 'Hippo',
    name: 'Hippo',
    idleEndFrame: 60,
    timeScale: EXTENDED_TIME_SCALE,
}

export const Hog: AnimalData = {
    key: 'Hog',
    name: 'Hog',
    idleEndFrame: 13,
}

export const Hornbill: AnimalData = {
    key: 'Hornbill',
    name: 'Hornbill',
    idleEndFrame: 13,
}

export const Hyena: AnimalData = {
    key: 'Hyena',
    name: 'Hyena',
    idleEndFrame: 15,
}

export const Mice: AnimalData = {
    key: 'Mice',
    name: 'Mice',
    idleEndFrame: 15,
}

export const Ostrich: AnimalData = {
    key: 'Ostrich',
    name: 'Ostrich',
    idleEndFrame: 15,
}

export const Owl: AnimalData = {
    key: 'Owl',
    name: 'Owl',
    idleEndFrame: 13,
}

export const Ox: AnimalData = {
    key: 'Ox',
    name: 'Ox',
    idleEndFrame: 13,
}

export const Parrot: AnimalData = {
    key: 'Parrot',
    name: 'Parrot',
    idleEndFrame: 15,
}

export const Pelican: AnimalData = {
    key: 'Pelican',
    name: 'Pelican',
    idleEndFrame: 60,
    timeScale: EXTENDED_TIME_SCALE,
}

export const Penguin: AnimalData = {
    key: 'Penguin',
    name: 'Penguin',
    idleEndFrame: 15,
}

export const Pig: AnimalData = {
    key: 'Pig',
    name: 'Pig',
    idleEndFrame: 15,
}

export const Pigeon: AnimalData = {
    key: 'Pigeon',
    name: 'Pigeon',
    idleEndFrame: 15,
}

export const PolarBear: AnimalData = {
    key: 'PolarBear',
    name: 'Polar Bear',
    idleEndFrame: 15,
}

export const Rabbit: AnimalData = {
    key: 'Rabbit',
    name: 'Rabbit',
    idleEndFrame: 15,
}

export const Raccoon: AnimalData = {
    key: 'Raccoon',
    name: 'Raccoon',
    idleEndFrame: 13,
}

export const Reindeer: AnimalData = {
    key: 'Reindeer',
    name: 'Reindeer',
    idleEndFrame: 13,
}

export const Rhino: AnimalData = {
    key: 'Rhino',
    name: 'Rhino',
    idleEndFrame: 60,
    timeScale: EXTENDED_TIME_SCALE,
}

export const Rooster: AnimalData = {
    key: 'Rooster',
    name: 'Rooster',
    idleEndFrame: 12,
}

export const Seal: AnimalData = {
    key: 'Seal',
    name: 'Seal',
    idleEndFrame: 15,
}

export const Sheep: AnimalData = {
    key: 'Sheep',
    name: 'Sheep',
    idleEndFrame: 15,
}

export const Snake: AnimalData = {
    key: 'Snake',
    name: 'Snake',
    idleEndFrame: 15,
}

export const SnowOwl: AnimalData = {
    key: 'SnowOwl',
    name: 'Snow Owl',
    idleEndFrame: 13,
}

export const SnowWeasel: AnimalData = {
    key: 'SnowWeasel',
    name: 'Snow Weasel',
    idleEndFrame: 15,
}

export const Tortoise: AnimalData = {
    key: 'Tortoise',
    name: 'Tortoise',
    idleEndFrame: 15,
}

export const Walrus: AnimalData = {
    key: 'Walrus',
    name: 'Walrus',
    idleEndFrame: 15,
}

export const Wolf: AnimalData = {
    key: 'Wolf',
    name: 'Wolf',
    idleEndFrame: 13,
}

export const Zebra: AnimalData = {
    key: 'Zebra',
    name: 'Zebra',
    idleEndFrame: 15,
}


export const ANIMALS_DATA: AnimalData[] = [
    ArcticFox,
    Buffalo,
    Cat,
    Cheetah,
    Chick,
    Cow,
    Crow,
    Dog,
    Donkey,
    Dove,
    Duck,
    Eagle,
    Elephant,
    Flamingo,
    Fox,
    Gazelle,
    Goldfish,
    Hen,
    Hippo,
    Hog,
    Hornbill,
    Hyena,
    Mice,
    Ostrich,
    Owl,
    Ox,
    Parrot,
    Pelican,
    Penguin,
    Pig,
    Pigeon,
    PolarBear,
    Rabbit,
    Raccoon,
    Reindeer,
    Rhino,
    Rooster,
    Seal,
    Sheep,
    Snake,
    SnowOwl,
    SnowWeasel,
    Tortoise,
    Walrus,
    Wolf,
    Zebra,
]

export const getAnimalData = (animal: string): AnimalData | null => {
    const match = ANIMALS_DATA.find(data => data.key === animal)
    return match ?? null
}

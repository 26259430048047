import * as React from "react"
import Modal from 'react-modal';
import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import {setMuteUnmuteFunctions, setShowVideoModal, useShowVideoModal} from "../demoScene/state";
import {MODAL_CLASSES} from "../ui/global";
import {cssButtonReset} from "../ui/buttons";

const StyledVideoWrapper = styled.div`
  width: 0;
  height: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
`

export const VIDEO_TRAILER_ID = 'trailer-video'

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return iOS() || (ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0);
};

const teaserWebmUrl = 'https://storage.googleapis.com/skittish-website-files/teaser.webm'
const teaserMP4Url = 'https://storage.googleapis.com/skittish-website-files/teaser.mp4'
// const teaserWebmUrl = '/videos/teaser.webm'
// const teaserMP4Url = '/videos/teaser.mp4'

const mobileTeaserWebmUrl = 'https://storage.googleapis.com/skittish-website-files/mobile-teaser.webm'
const mobileTeaserMP4Url = 'https://storage.googleapis.com/skittish-website-files/mobile-teaser.mp4'
// const mobileTeaserWebmUrl = '/videos/mobile-teaser.webm'
// const mobileTeaserMP4Url = '/videos/mobile-teaser.mp4'

const getVideoUrls = () => {

}

const StyledEmbedWrapper = styled.div`
    width: calc(100vw - 20px);
    line-height: 0;
    position: relative;

    iframe {
      width: 100%;
      height: calc((100vw - 20px) * 0.5625);
      line-height: 0;
    }

`

const StyledCloseButton = styled.button`
  ${cssButtonReset};
  position: absolute;
  right: -5px;
  bottom: 100%;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url("/svgs/close.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  opacity: 0.75;
`


export const VideoTrailer: React.FC<{
    isDesktopSize: boolean,
}> = ({isDesktopSize}) => {

    const videoParentRef = useRef<any>();
    const [shouldUseImage, setShouldUseImage] = useState(false);

    useEffect(() => {
        // check if user agent is safari and we have the ref to the container <div />
        if (isSafari() && videoParentRef.current) {
            console.log('is safari, is being instructed to auto-play....')
            // obtain reference to the video element
            const player = videoParentRef.current.children[0];

            // if the reference to video player has been obtained
            if (player) {
                // set the video attributes using javascript as per the
                // webkit Policy
                player.controls = false;
                player.playsinline = true;
                player.muted = true;
                player.setAttribute("muted", ""); // leave no stones unturned :)
                player.autoplay = true;

                // Let's wait for an event loop tick and be async.
                setTimeout(() => {
                    // player.play() might return a promise but it's not guaranteed crossbrowser.
                    const promise = player.play();
                    // let's play safe to ensure that if we do have a promise
                    if (promise.then) {
                        promise
                            .then(() => {})
                            .catch(() => {
                                // if promise fails, hide the video and fallback to <img> tag
                                // videoParentRef.current.style.display = "none";
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
    }, []);

    useEffect(() => {

        if (!videoParentRef.current) return

        const videoPlayer = videoParentRef.current.children[0]

        if (!videoPlayer) return

        const unmute = () => {
            videoPlayer.muted = false;
            videoPlayer.setAttribute("muted", "false");
        }

        const mute = () => {
            videoPlayer.muted = true;
            videoPlayer.setAttribute("muted", "");
        }

        setMuteUnmuteFunctions(mute, unmute)

    }, [])

    const showModal = useShowVideoModal()

    return (
        <>
            <StyledVideoWrapper ref={videoParentRef}>
                <video id={VIDEO_TRAILER_ID} crossOrigin="anonymous" playsInline muted autoPlay loop width={"1080"}>
                    {
                        isDesktopSize ? (
                            <>
                                <source src={teaserWebmUrl}
                                        type="video/webm"/>
                                <source src={teaserMP4Url}
                                        type="video/mp4"/>
                            </>
                        ) : (
                            <>
                                <source src={mobileTeaserWebmUrl}
                                        type="video/webm"/>
                                <source src={mobileTeaserMP4Url}
                                        type="video/mp4"/>
                            </>
                        )
                    }
                </video>
            </StyledVideoWrapper>
            <Modal className={MODAL_CLASSES.modal}
                   overlayClassName={MODAL_CLASSES.overlay} isOpen={showModal} onRequestClose={() => {
                       setShowVideoModal(false)
            }}>
                <StyledEmbedWrapper>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/msjoJ-gySrs?autoplay=1"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    <StyledCloseButton onClick={() => {
                        setShowVideoModal(false)
                    }}/>
                </StyledEmbedWrapper>
            </Modal>
        </>
    )
}

export const BREAKPOINTS = {
    mobile: 770,
}

export const minQuery = (breakpoint: number) =>
    `@media (min-width: ${breakpoint}px)`

export const maxQuery = (breakpoint: number) =>
    `@media (max-width: ${breakpoint}px)`

export const MEDIA_BREAKPOINTS = {
    maxMobile: maxQuery(BREAKPOINTS.mobile),
    minDesktop: minQuery(BREAKPOINTS.mobile + 1),
}

export const isDesktopSize = (): boolean => {
    if (typeof window === `undefined`) {
        return false
    }
    return window.innerWidth >= BREAKPOINTS.mobile + 1
}

export const MOBILE_BREAKPOINT = MEDIA_BREAKPOINTS.maxMobile

export const DESKTOP_BREAKPOINT = MEDIA_BREAKPOINTS.minDesktop

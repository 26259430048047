import styled from "styled-components";
import {DESKTOP_BREAKPOINT} from "./responsive";

export const StyledMaxWidth = styled.div`
  
  ${DESKTOP_BREAKPOINT} {
    width: 100%;
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  
`

import * as React from "react"
import {useCallback, useEffect, useMemo, useRef} from "react"
import {PerspectiveCamera} from "@react-three/drei";
import {Object3D, PerspectiveCamera as PerspectiveCameraImpl} from "three"
import {subscribe} from "valtio";
import {CurrentStep, demoStateProxy, useCurrentStep, useNextStep} from "./state";
import {animated, useSpring} from '@react-spring/three'
import {useFrame} from "@react-three/fiber";
import {DesktopWidthType, useDesktopWidthType} from "../utils/responsive";

const step1Position: [number, number, number] = [0, 1.25, 15]
const step2Position: [number, number, number] = [10, 25, 20]

const mobileStep2Position: [number, number, number] = [15, 25, 20]

const step3Position: [number, number, number] = [0, 25, 32]

const mobileStep3Position: [number, number, number] = [8, 25, 32]

const step4Position: [number, number, number] = [-7, 15, 25]

const mobileStep4Position: [number, number, number] = [-4, 15, 25]

const step5Position: [number, number, number] = [0, 1.25, 15]

const step1LookAt: [number, number, number] = [0, 2.75, 0]
const step2LookAt: [number, number, number] = [10, 2.75, 10]

const mobileStep2LookAt: [number, number, number] = [15, 2.75, 10]

const step3LookAt: [number, number, number] = [0, 2.75, 22]

const mobileStep3LookAt: [number, number, number] = [4, 2.75, 22]

const step4LookAt: [number, number, number] = [-7, 2.75, 15]

const mobileStep4LookAt: [number, number, number] = [-4, 2.75, 15]

const step5LookAt: [number, number, number] = [0, 2.75, 0]

const desktopPositionSteps = {
    [CurrentStep.FIRST]: {
        cameraPosition: [step1Position, step2Position],
        lookAt: [step1LookAt, step2LookAt],
    },
    [CurrentStep.SECOND]: {
        cameraPosition: [step2Position, step3Position],
        lookAt: [step2LookAt, step3LookAt],
    },
    [CurrentStep.THIRD]: {
        cameraPosition: [step3Position, step4Position],
        lookAt: [step3LookAt, step4LookAt],
    },
    [CurrentStep.FOURTH]: {
        cameraPosition: [step4Position, step5Position],
        lookAt: [step4LookAt, step5LookAt],
    },
}

const mobilePositionSteps = {
    [CurrentStep.FIRST]: {
        cameraPosition: [step1Position, mobileStep2Position],
        lookAt: [step1LookAt, mobileStep2LookAt],
    },
    [CurrentStep.SECOND]: {
        cameraPosition: [mobileStep2Position, mobileStep3Position],
        lookAt: [mobileStep2LookAt, mobileStep3LookAt],
    },
    [CurrentStep.THIRD]: {
        cameraPosition: [mobileStep3Position, mobileStep4Position],
        lookAt: [mobileStep3LookAt, mobileStep4LookAt],
    },
    [CurrentStep.FOURTH]: {
        cameraPosition: [mobileStep4Position, step5Position],
        lookAt: [mobileStep4LookAt, step5LookAt],
    },
}

const lightConfig = {
    top: -20,
    left: -20,
    right: 20,
    bottom: 16,
}

export const Camera: React.FC<{
    isDesktopSize: boolean,
}> = ({isDesktopSize}) => {

    const cameraRef = useRef<PerspectiveCameraImpl>()
    const lookAtRef = useRef<Object3D>()

    const positionSteps = isDesktopSize ? desktopPositionSteps : mobilePositionSteps

    useFrame(() => {
        if (!lookAtRef.current) return
        cameraRef.current.lookAt(lookAtRef.current.position)
    })

    const [{
        progress,
        progress2,
        progress3,
        progress4,
    }, api] = useSpring(() => ({
        progress: demoStateProxy.firstStepProgress,
        progress2: demoStateProxy.secondStepProgress,
        progress3: demoStateProxy.thirdStepProgress,
        progress4: demoStateProxy.fourthStepProgress,
        config: { mass: 1, tension: 280, friction: 60 },
    }))

    useEffect(() => {
        const unsubscribe = subscribe(demoStateProxy, () => {
            api.set({
                progress: demoStateProxy.firstStepProgress,
                progress2: demoStateProxy.secondStepProgress,
                progress3: demoStateProxy.thirdStepProgress,
                progress4: demoStateProxy.fourthStepProgress,
            })
        })
        return () => {
            unsubscribe()
        }
    }, [api])

    const currentStep = useCurrentStep()
    const nextStep = useNextStep()
    const desktopWidthType = useDesktopWidthType()

    const fovSource = useMemo(() => {
        if (!isDesktopSize) {
            if (nextStep === CurrentStep.START || nextStep === CurrentStep.FOURTH) {
                return 80
            }
            return 50
        } else {
            if (desktopWidthType === DesktopWidthType.EXTRA_SKINNY) {
                return 70
            } else if (desktopWidthType === DesktopWidthType.SKINNY) {
                return 60
            } else if (desktopWidthType === DesktopWidthType.SLIM) {
                return 50
            }
        }
        return 40
    }, [nextStep, isDesktopSize, desktopWidthType])

    useEffect(() => {
        cameraRef.current.fov = fovSource
        cameraRef.current.updateProjectionMatrix()
    }, [])

    const updateFov = useCallback((update: any) => {
        const updatedFov = update?.value?.fov ?? 0
        if (updatedFov && cameraRef.current) {
            cameraRef.current.fov = updatedFov
            cameraRef.current.updateProjectionMatrix()
        }
    }, [])

    useSpring({
        fov: fovSource,
        onChange: updateFov,
        config: { mass: 5, tension: 5, friction: 5, clamp: true },
    })

    const {
        positionX,
        positionY,
        positionZ,
        lookPositionX,
        lookPositionY,
        lookPositionZ,
    } = useMemo(() => {

        const args = [0, 100]

        const config = positionSteps[currentStep]

        const [initialPosition, targetPosition] = config.cameraPosition
        const [lookAtStart, lookAtEnd] = config.lookAt

        const spring = currentStep === CurrentStep.FIRST ? progress :
                        currentStep === CurrentStep.SECOND ? progress2 :
                            currentStep === CurrentStep.THIRD ? progress3 :
                                progress4

        return {
            positionX: spring.to(args, [initialPosition[0], targetPosition[0]]),
            positionY: spring.to(args, [initialPosition[1], targetPosition[1]]),
            positionZ: spring.to(args, [initialPosition[2], targetPosition[2]]),
            lookPositionX: spring.to(args, [lookAtStart[0], lookAtEnd[0]]),
            lookPositionY: spring.to(args, [lookAtStart[1], lookAtEnd[1]]),
            lookPositionZ: spring.to(args, [lookAtStart[2], lookAtEnd[2]]),
        }
    }, [currentStep, progress, progress2, progress3, progress4, positionSteps])

    const {
        top,
        left,
        right,
        bottom,
        } = lightConfig
    // }
        // = useControls(lightConfig)

    const key = `${top}${left}${right}${bottom}`

    const lightRef = useRef<any>()

    useFrame(() => {
        if (!lightRef.current) return
        // lightRef.current.shadow.needsUpdate = true
    })

    return (
        <>
            <animated.group position-x={positionX} position-y={positionY} position-z={positionZ}>
                <PerspectiveCamera ref={cameraRef} makeDefault />
                <directionalLight intensity={0.2}
                                  shadow-mapSize-width={2048}
                                  shadow-mapSize-height={2048}
                                  castShadow
                                  position={[20, 10, 10]}
                                  shadowCameraFar={100}
                                  shadowCameraNear={0.1}
                                  shadowCameraTop={top}
                                  shadowCameraLeft={left}
                                  shadowCameraBottom={bottom}
                                  shadowCameraRight={right} key={key}
                                  shadowBias={-0.001} ref={lightRef}/>
            </animated.group>
            <animated.group position-x={lookPositionX} position-y={lookPositionY} position-z={lookPositionZ} ref={lookAtRef}/>
        </>
    )
}

export const PRIVACY_URL = '/privacy'
export const TERMS_URL = '/tos'
export const DEMO_URL = 'https://live.skittish.com/demo'
export const SIGNIN_URL = 'https://live.skittish.com/signup'

export const TWITTER_URL = 'https://twitter.com/SkittishHQ'
export const INSTA_URL = 'https://www.instagram.com/SkittishHQ/'
export const CONTACT_EMAIL = 'mailto:hi@skittish.com'

export const TECH_CRUNCH_URL = 'https://techcrunch.com/2021/05/18/skittish-andy-baio-virtual-events/'
export const THE_VERGE_URL = 'https://www.theverge.com/2021/2/17/22286520/skittish-virtual-event-space-online-andy-baio'
export const WIRED_URL = 'https://www.wired.com/story/skittish-andy-baio/'

import * as React from "react"
import {Character} from "./Character";
import {degToRad} from "three/src/math/MathUtils";
import {CurrentStep, useNextStep} from "./state";
import {useEffect, useMemo, useRef, useState} from "react";
import {animated, useSpring} from "@react-spring/three";
import {calculateAngleBetweenVectors} from "../utils/angles";

const xOffset = -2
const zOffset = 8

const characterPositions = {
    [CurrentStep.START]: {
        position: [7, 0, -1.5],
        movingRotation: degToRad(-170),
        restingRotation: degToRad(-170),
    },
    [CurrentStep.FIRST]: {
        position: [14, 0, 10],
        movingRotation: degToRad(30),
        restingRotation: degToRad(15),
    },
    [CurrentStep.SECOND]: {
        position: [xOffset + 8, 0, zOffset + 10],
        movingRotation: degToRad(-15),
        restingRotation: degToRad(-10),
    },
    [CurrentStep.THIRD]: {
        position: [-4, 0, 12],
        movingRotation: degToRad(-130),
        restingRotation: degToRad(-45),
    },
    [CurrentStep.FOURTH]: {
        position: [7, 0, -1.5],
        movingRotation: degToRad(-220),
        restingRotation: degToRad(-170),
    },
}

export const MainCharacter: React.FC = () => {

    const groupRef = useRef<any>()
    const nextStep = useNextStep()
    const [targetStep, setTargetStep] = useState(nextStep)
    const localStateRef = useRef({
        currentTargetStep: nextStep,
        targetPosition: [0, 0]
    })
    const [atRest, setAtRest] = useState(true)
    const [movementAngle, setMovementAngle] = useState(0)

    useEffect(() => {

        const setStep = () => {
            localStateRef.current.currentTargetStep = nextStep
            setTargetStep(nextStep)
        }

        if (nextStep > localStateRef.current.currentTargetStep) {
            setStep()
        } else {
            const timeout = setTimeout(() => {
                setStep()
            }, 500)
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [nextStep])

    const {
        xPos,
        zPos
    } = useMemo(() => {
        const step = characterPositions[targetStep]
        if (!step) {
            return {
                xPos: 0,
                zPos: 0,
            }
        }
        const [xPos, , zPos] = step.position
        localStateRef.current.targetPosition[0] = xPos
        localStateRef.current.targetPosition[1] = zPos
        return {
            xPos,
            zPos,
        }
    }, [targetStep])

    const {
        targetAngle,
    } = useMemo(() => {
        const step = characterPositions[targetStep]
        if (!step) {
            return {
                targetAngle: 0,
            }
        }
        if (!atRest && groupRef.current) {
            const [xPos, , zPos] = step.position
            const angle = calculateAngleBetweenVectors(xPos, groupRef.current.position.x, zPos, groupRef.current.position.z)
            return {
                targetAngle: angle,
            }
        }
        return {
            targetAngle: atRest ? step.restingRotation : step.movingRotation,
        }
    }, [targetStep, atRest])


    const {
        x,
        z,
    } = useSpring({
        x: xPos,
        z: zPos,
        // config: { mass: 3, tension: 10, friction: 10, },
        config: { mass: 10, tension: 5, friction: 5, clamp: true },
        onStart: () => {
            setAtRest(false)
        },
        onChange: ({value}: any) => {
            const targetPosition = localStateRef.current.targetPosition
            const {x, z} = value
            const remainingX = Math.abs(targetPosition[0] - x)
            const remainingZ = Math.abs(targetPosition[1] - z)
            if (remainingX <= 0.5 && remainingZ <= 0.5) {
                setAtRest(true)
            }
        },
        onRest: () => {
            setAtRest(true)
        }
    })

    const {
        angle,
    } = useSpring({
        angle: targetAngle,
        config: { mass: 1, tension: 50, friction: 10 },
    })

    return (
        <animated.group position-x={x} position-z={z} rotation-y={angle} ref={groupRef}>
            <Character loadWalking animal={'Rhino'} higherQuality moving={!atRest} position={[0, 0, 0]} rotation={[0, 0, 0]}/>
        </animated.group>
    )
}

export const Characters: React.FC = () => {
    return (
        <>
            <MainCharacter/>
            <Character animal={'Owl'} higherQuality position={[-5, 0, 0]} rotation={[0, degToRad(180), 0]}/>
            <Character animal={'Elephant'} higherQuality position={[-7.5, 0, -1.5]} rotation={[0, degToRad(170), 0]}/>
            <Character animal={'Penguin'} position={[xOffset + 12, 0, zOffset + 13]} rotation={[0, degToRad(-70), 0]}/>
            <Character animal={'Seal'} position={[xOffset + 11.5, 0, zOffset + 16.5]} rotation={[0, degToRad(-120), 0]}/>
            <Character animal={'Tortoise'} position={[xOffset + 8, 0, zOffset + 19]} rotation={[0, degToRad(-180), 0]}/>
            <Character animal={'Zebra'} position={[xOffset + 4, 0, zOffset + 17.5]} rotation={[0, degToRad(-215), 0]}/>
        </>
    )
}

import * as React from "react"
import styled, {css} from "styled-components";
import {GlobalWrapper} from "../components/GlobalWrapper";
import {cssZIndexTop, THEME} from "../ui/theme";
import {StyledRoundedButton} from "../ui/buttons"
import {Testimonials} from "../components/Testimonials";
import {StyledMaxWidth} from "../ui/layout";
import {Waypoint} from "react-waypoint";
import {useCallback, useEffect, useRef, useState} from "react";
import {DemoScene} from "../demoScene/DemoScene";
// @ts-ignore
import skittishLogo from "../assets/skittish.svg";
import {VideoTrailer} from "../components/VideoTrailer";
import { throttle } from "lodash-es";
import {setDemoProgress} from "../demoScene/state";
import {windowExists} from "../utils/window";
import {DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT} from "../ui/responsive";
// @ts-ignore
import Sticky from "react-stickynode"
import {CONTACT_EMAIL, DEMO_URL, INSTA_URL, PRIVACY_URL, SIGNIN_URL, TERMS_URL, TWITTER_URL} from "../data";
import {useIsDesktopSize} from "../utils/responsive";

export const cssFullHeight = css`
  height: 100vh;
  
  ${MOBILE_BREAKPOINT} {

    @supports (-webkit-touch-callout: none) {
      height: -webkit-fill-available;
      max-height: -webkit-fill-available;
    }
    
  }
  
`

const StyledTopHeader = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.2rem;
  top: ${THEME.spacing.$3}px;
  left: ${THEME.spacing.$3}px;
  right: ${THEME.spacing.$3}px;
  z-index: 99999;
  
  ${MOBILE_BREAKPOINT} {
    top: ${THEME.spacing.$1b}px;
    left: ${THEME.spacing.$1b}px;
    right: ${THEME.spacing.$1b}px;
    align-items: center;
  }
  
`

const StyledTopOptions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${THEME.spacing.$1b}px;
`

const cssSmallerTitle = css`
  
  ${DESKTOP_BREAKPOINT} {
    transform: scale(0.55)
  }
  
`

const StyledSkittishTitle = styled.div<{
    smaller?: boolean,
}>`
  color: white;
  font-weight: 700;
  transition: all 350ms ease;
  transform-origin: left top;
  ${props => props.smaller ? cssSmallerTitle : ''};
  
  ${DESKTOP_BREAKPOINT} {
    font-size: 2.5rem;
  }
  
  img {
    width: 180px;

    ${MOBILE_BREAKPOINT} {
      width: 100px;
    }
    
  }
  
`

const StyledSkittishAnnouncement = styled.div<{
  smaller?: boolean,
}>`
color: white;
font-weight: 700;
text-align: center;
margin: 10px;
transition: all 350ms ease;
transform-origin: left top;
${props => props.smaller ? cssSmallerTitle : ''};

${DESKTOP_BREAKPOINT} {
  font-size: 1.5rem;
}

${MOBILE_BREAKPOINT} {
  font-size: 1rem;
}

a {
  color: #ff4fce;
  text-decoration: underline;
  
  &:hover,
  &:focus {
    color: #fff;
  }
  
}
`

const StyledTopSection = styled.section`
  ${cssFullHeight};
  color: white;
  position: relative;
  margin-bottom: 25vh;
`

const StyledTopSectionContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 80px;
  display: flex;
  justify-content: center;
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  ${cssZIndexTop};
  
  h1 {
    font-size: 3rem;
    margin-bottom: ${THEME.spacing.$3}px;
  }
  
`

const StyledInfoSection = styled.section`
  ${cssFullHeight};
  display: flex;
  align-items: center;
`

const StyledInfoContent = styled.div`
  //background-color: white;
  color: white;
  font-size: 1.6rem;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  position: relative;
  ${cssZIndexTop};
  
  ${DESKTOP_BREAKPOINT} {
    padding: 50px 40px;
    width: 640px;
  }
  
  ${MOBILE_BREAKPOINT} {
    padding: 20px;
    width: 100%;
    // background-color: rgba(49, 70, 30, 0.4);
    font-size: 1.2rem;
  }
  
  h3 {
    font-size: 2.8rem;
    margin-bottom: ${THEME.spacing.$1b}px;
    
    ${MOBILE_BREAKPOINT} {
      font-size: 2rem;
    }
    
  }
  
  p {
    max-width: 370px;
    line-height: 1.25;
  }
  
  strong {
    font-weight: 700;
  }
  
`

const StyledConclusionSection = styled(StyledMaxWidth)`
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  color: white;
  padding-bottom: 10vh;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  
  ${MOBILE_BREAKPOINT} {
    padding-bottom: 18vh;
  }
  
  div {
    ${cssZIndexTop};
  }
  
  footer {
  }

  
  h3 {
    font-size: 2rem;
    margin-bottom: ${THEME.spacing.$3}px;
    
    ${MOBILE_BREAKPOINT} {
      font-size: 1.5rem;
    }
    
  }
  
`

const StyledWrapper = styled.div`
    position: relative;
`

const StyledFooter = styled.footer`
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 15px;
  ${cssZIndexTop};
  
  ${DESKTOP_BREAKPOINT} {
    display: flex;
    justify-content: space-between;
  }
  
`

const StyledLinksContainer = styled.ul`

  ${DESKTOP_BREAKPOINT} {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 7px;
    font-size: 0.9rem;
  }

    ${MOBILE_BREAKPOINT} {
      display: flex;
      justify-content: center;
      
      &:not(:first-child) {
        margin-top: 8px;
      }
      
    }
  
  > li {

    &:not(:last-child) {

      ${MOBILE_BREAKPOINT} {
        margin-right: 7px;
      }

      &::after {
        content: '•';
        margin-left: 7px;
      }

    }
    
  }
  
  a {
    color: inherit;
    text-decoration: none;
    
    &:hover,
    &:focus {
      color: #ff4fce;
      text-decoration: underline;
    }
    
  }
  
`

const calculateStepY = (step: any) => {
    if (!step) return 0
    const firstStepBounding = step.getBoundingClientRect()
    const firstStepHeightOffset = (firstStepBounding.height)
    return window.scrollY + (firstStepBounding.top) - firstStepHeightOffset
}

const StyledHTMLContainer = styled.div`
  position: fixed;
  top: 0;
  
  > div {
    overflow: visible !important;
  }
  
`

const Landing: React.FC = () => {

    const [atTop, setAtTop] = useState(true)
    const [atBottom, setAtBottom] = useState(false)
    const [atTestimonials, setAtTestimonials] = useState(false)

    const smallerTitle = !atTop && !atBottom || (atBottom && atTestimonials)

    const containerRef = useRef<any>()
    const firstStepRef = useRef<any>()
    const secondStepRef = useRef<any>()
    const thirdStepRef = useRef<any>()

    const localStateRef = useRef({
        scrollHeight: windowExists() ? window.screen.height : 0,
        screenHeight: windowExists() ? window.screen.height : 0,
        firstStepY: windowExists() ? window.screen.height : 0,
        secondStepY: windowExists() ? window.screen.height : 0,
        thirdStepY: windowExists() ? window.screen.height : 0,
    })

    const calculatePositions = useCallback(() => {
        const screenHeight = window.screen.height
        localStateRef.current.screenHeight = screenHeight
        localStateRef.current.firstStepY = calculateStepY(firstStepRef.current)
        localStateRef.current.secondStepY = calculateStepY(secondStepRef.current)
        localStateRef.current.thirdStepY = calculateStepY(thirdStepRef.current)
        localStateRef.current.scrollHeight = containerRef.current.scrollHeight - window.screen.height
    }, [])

    useEffect(() => {
        calculatePositions()
        const throttled = throttle(calculatePositions, 100)
        const interval = setInterval(calculatePositions, 5000)
        window.addEventListener('resize', throttled)
        return () => {
            clearInterval(interval)
            window.removeEventListener('resize', throttled)
        }
    }, [calculatePositions])

    const calculateProgress = useCallback(() => {
        let scroll = window.scrollY;
        if (scroll < 0) {
            scroll = 0
        }
        const firstStepThreshold = localStateRef.current.firstStepY + localStateRef.current.screenHeight / 2
        const progess = scroll > firstStepThreshold ? firstStepThreshold : scroll
        const percentage = Math.round((progess / firstStepThreshold) * 10000) / 100

        const secondStepMin = firstStepThreshold + localStateRef.current.screenHeight / 3
        const secondStepMax = localStateRef.current.secondStepY + localStateRef.current.screenHeight / 2
        const secondStepProg = scroll < secondStepMin ? secondStepMin : scroll > secondStepMax ? secondStepMax : scroll
        const secondStepDiff = secondStepMax - secondStepMin
        const secondStep = ((secondStepProg - secondStepMin) / secondStepDiff) * 100

        const thirdStepMin = secondStepMax + localStateRef.current.screenHeight / 3
        const thirdStepMax = localStateRef.current.thirdStepY + localStateRef.current.screenHeight / 2
        const thirdStepProg = scroll < thirdStepMin ? thirdStepMin : scroll > thirdStepMax ? thirdStepMax : scroll
        const thirdStepDiff = thirdStepMax - thirdStepMin
        const thirdStep = ((thirdStepProg - thirdStepMin) / thirdStepDiff) * 100

        const fourthStepMin = localStateRef.current.thirdStepY + localStateRef.current.screenHeight - 100
        const fourthStepMax = localStateRef.current.scrollHeight
        const fourthStepScroll = scroll
        const fourthStepProgress = fourthStepScroll < fourthStepMin ? fourthStepMin : fourthStepScroll > fourthStepMax ? fourthStepMax : fourthStepScroll
        const difference = fourthStepMax - fourthStepMin
        const adjusted = ((fourthStepProgress - fourthStepMin) / difference) * 100

        setDemoProgress(percentage, secondStep, thirdStep, adjusted)

    }, [])

    const calculateProgressRef = useRef(calculateProgress)

    useEffect(() => {
        calculateProgressRef.current = calculateProgress
        calculateProgress()
    }, [calculateProgress])

    useEffect(() => {
        const onScroll = throttle((event: Event) => {
            calculateProgressRef.current()
        }, 10)
        document.addEventListener('scroll', onScroll)
        return () => {
            document.removeEventListener('scroll', onScroll)
        }
    }, [])

    const htmlContainerRef = useRef<any>()

    const isDesktopSize = useIsDesktopSize()

    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])

    return (
        <GlobalWrapper>
            {
                mounted && (
                    <VideoTrailer isDesktopSize={isDesktopSize}/>
                )
            }
            <DemoScene isDesktopSize={isDesktopSize} htmlContainerRef={htmlContainerRef}/>
            <StyledWrapper ref={containerRef}>
                <StyledTopHeader>
                    <StyledSkittishTitle smaller={smallerTitle}>
                        <img src={skittishLogo} alt={"Skittish logo"}/>
                    </StyledSkittishTitle>
                    <StyledSkittishAnnouncement>
                        Skittish is closing December 14.<br />
                        <a href="https://new.skittish.com/2022/winding-down-skittish/">Learn more</a>.
                    </StyledSkittishAnnouncement>
                    <StyledTopOptions>
                        <StyledRoundedButton clear as={'a'} href={SIGNIN_URL}>
                            Sign in
                        </StyledRoundedButton>
                        <StyledRoundedButton as={'a'} href={DEMO_URL}>
                            Try it now
                        </StyledRoundedButton>
                    </StyledTopOptions>
                </StyledTopHeader>
                <StyledTopSection>
                    <Waypoint onEnter={() => {
                        setAtTop(true)
                    }} onLeave={() => {
                        setAtTop(false)
                    }}>
                        <StyledTopSectionContent ref={firstStepRef}>
                            <div>
                                <h1>
                                    A playful space<br/> for online events
                                </h1>
                                <StyledRoundedButton as={'a'} href={DEMO_URL}>
                                    Get started for free
                                </StyledRoundedButton>
                            </div>
                        </StyledTopSectionContent>
                    </Waypoint>
                </StyledTopSection>
                <StyledInfoSection>
                    <StyledMaxWidth>
                        <StyledInfoContent ref={secondStepRef}>
                            <h3>
                                This is no webinar.
                            </h3>
                            <p>
                                Host conferences, festivals, meetups, parties, workshops, and more in a colorful game-like 3D world.
                            </p>
                        </StyledInfoContent>
                    </StyledMaxWidth>
                </StyledInfoSection>
                <StyledInfoSection>
                    <StyledMaxWidth>
                        <StyledInfoContent ref={thirdStepRef}>
                            <h3>
                                Camera shy? No problem.
                            </h3>
                            <p>
                                Use your mic to talk to others near you with our best-of-class 3D spatial audio.
                                Done chatting? Just walk away.
                            </p>
                        </StyledInfoContent>
                    </StyledMaxWidth>
                </StyledInfoSection>
                <StyledInfoSection>
                    <StyledMaxWidth>
                        <StyledInfoContent>
                            <h3>
                                Discover your inner animal.
                            </h3>
                            <p>
                                Choose from over 45 animal avatars to represent you — from buffalo to zebra.
                            </p>
                        </StyledInfoContent>
                    </StyledMaxWidth>
                </StyledInfoSection>
                <Waypoint onEnter={() => {
                    setAtTestimonials(true)
                }} onLeave={() => {
                    setAtTestimonials(false)
                }} bottomOffset={50} topOffset={50}>
                    <div>
                        <Testimonials/>
                    </div>
                </Waypoint>
                <StyledConclusionSection>
                    <Waypoint onEnter={() => {
                        setAtBottom(true)
                    }} onLeave={() => {
                        setAtBottom(false)
                    }}>
                        <div>
                            <h3>
                                Feeling a little Skittish?
                            </h3>
                            <StyledRoundedButton as={'a'} href={DEMO_URL}>
                                Get started for free
                            </StyledRoundedButton>
                        </div>
                    </Waypoint>
                    <StyledFooter>
                        <StyledLinksContainer>
                            <li>
                                <a href={TWITTER_URL} target={'_blank'}>
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href={INSTA_URL} target={'_blank'}>
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a href={CONTACT_EMAIL}>
                                    Contact
                                </a>
                            </li>
                        </StyledLinksContainer>
                        <StyledLinksContainer>
                            <li>
                                <a href={TERMS_URL} target={'_blank'}>
                                    Terms & Conditions
                                </a>
                            </li>
                            <li>
                                <a href={PRIVACY_URL} target={'_blank'}>
                                    Privacy Policy
                                </a>
                            </li>
                        </StyledLinksContainer>
                    </StyledFooter>
                </StyledConclusionSection>
                <StyledHTMLContainer ref={htmlContainerRef}/>
            </StyledWrapper>
        </GlobalWrapper>
    )
}

export default Landing

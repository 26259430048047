import * as React from "react"
import {Box, Html} from "@react-three/drei";
import {degToRad} from "three/src/math/MathUtils";
import {useCallback, useEffect, useState} from "react";
import {VIDEO_TRAILER_ID} from "../components/VideoTrailer";
import {VideoTexture} from "three";
import {useSnapshot} from "valtio";
import {demoUIProxy, setShowVideoModal} from "./state";
import styled, {css} from "styled-components";
import {cssButtonReset} from "../ui/buttons";

const sizeRatio = 1280 / 720
const height = 9.5
const width = height * sizeRatio

const cssInteractedWith = css`
  transform-origin: top right;
  transform: scale(0.75);
  background-color: rgba(0,0,0,0.05);
`

const cssMuted = css`
  background-image: url("/svgs/volumeMuted.svg");
  background-size: 19px;

  &:focus {
    opacity: 1;
    background-color: rgba(0,0,0,0.25);
  }
  
`

const cssNotMuted = css`
  background-image: url("/svgs/volumeUp.svg");
  background-size: 20px;
  
`

const StyledButtonContainer = styled.div<{
    interacted?: boolean,
    muted?: boolean,
}>`
  position: relative;
  
  button {
    ${cssButtonReset};
    position: absolute;
    right: 0;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.15);
    opacity: 0.75;
    transition: all 250ms ease;
    background-position: center;
    background-repeat: no-repeat;
    color: transparent;
    ${props => props.muted ? cssMuted : cssNotMuted};
    
    ${props => props.interacted ? cssInteractedWith : ''};
    
    &:hover {
      opacity: 1;
      background-color: rgba(0,0,0,0.25);
    }
    
  }
  
`

const StyledPlayWrapper = styled.button`
  ${cssButtonReset};
  display: block;
  width: 160px;
  height: 160px;
  background-color: rgba(0,0,0,0.15);
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/svgs/play.svg");
  background-size: 120px;
  opacity: 0.95;
  color: transparent;
`

export const VideoScreen: React.FC<{
    htmlContainerRef: any,
    isDesktopSize: boolean,
}> = ({htmlContainerRef, isDesktopSize}) => {

    const [videoTexture, setVideoTexture] = useState<null | VideoTexture>(null)

    useEffect(() => {
        const videoElement = document.getElementById(VIDEO_TRAILER_ID)
        if (!videoElement) {
            // todo - try again?
            return
        }
        const texture = new VideoTexture(videoElement as HTMLVideoElement);
        setVideoTexture(texture)
    }, [])

    const {
        unmuteVideo,
        muteVideo,
    } = useSnapshot(demoUIProxy)

    const [muted, setMuted] = useState(true)
    const [interacted, setInteracted] = useState(false)

    const toggleMuted = useCallback(() => {
        if (muted) {
            unmuteVideo()
            setMuted(false)
        } else {
            muteVideo()
            setMuted(true)
        }
        setInteracted(true)
    }, [muted, unmuteVideo, muteVideo])

    return (
        <group position={[0, 0.25, -10]}>
            <Box castShadow position={[0, height / 2, 0]} args={[width, height, 0.02]} rotation={[degToRad(6), 0, 0]}>
                {
                    videoTexture && (
                        <meshBasicMaterial map={videoTexture}/>
                    )
                }
            </Box>
            {/*<Box position={[0, height / 2, 0.1]} args={[width, height, 0.02]} rotation={[degToRad(6), 0, 0]}>*/}
            {/*    <meshBasicMaterial color={'#000000'} transparent opacity={0.5} />*/}
            {/*</Box>*/}
            {
                (isDesktopSize || !muted) && (
                    <Html portal={htmlContainerRef} zIndexRange={[50, 0]} transform position={[width / 2, height, 0]}>
                        <StyledButtonContainer muted={muted} interacted={interacted}>
                            <button onClick={toggleMuted}>
                                {
                                    muted ? "Unmute" : "Mute"
                                }
                            </button>
                        </StyledButtonContainer>
                    </Html>
                )
            }
            {
                !isDesktopSize && (
                    <>
                        <Html center portal={htmlContainerRef} position={[0, height / 2, 0]} zIndexRange={[50, 0]} transform>
                            <StyledPlayWrapper onClick={() => {
                                setShowVideoModal(true)
                            }}>
                                PLAY
                            </StyledPlayWrapper>
                        </Html>
                    </>
                )
            }
        </group>
    )
}

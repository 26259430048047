import * as React from "react"
import styled from "styled-components";
import {Canvas, useThree} from "@react-three/fiber";
import {Box} from "@react-three/drei";
import {THEME} from "../ui/theme";
import {Scenery} from "./Scenery";
import {Camera} from "./Camera";
import {Characters} from "./Characters";
import {BasicShadowMap} from "three";
import {useIsDesktopSize} from "../utils/responsive";
import {useEffect} from "react";

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  background: rgba(76, 157, 255, 1);
  background: linear-gradient(0deg, rgb(66, 144, 238) 21%, rgba(121, 197, 255, 1) 85%);

  canvas {
    position: relative;
  }

`

const DPRHandler: React.FC<{
    isDesktopSize: boolean,
}> = ({isDesktopSize}) => {

    const {
        setDpr
    } = useThree()

    useEffect(() => {
        try {
            const dpr = window.devicePixelRatio ?? 1
            const maxDpr = dpr > 2 ? 2 : dpr
            setDpr(isDesktopSize ? 1 : maxDpr)
        } catch (e) {
            console.warn(e)
        }
    }, [isDesktopSize])

    return null
}

export const DemoScene: React.FC<{
    isDesktopSize: boolean,
    htmlContainerRef: any,
}> = ({isDesktopSize, htmlContainerRef}) => {

    return (
        <StyledContainer>
            <Canvas shadows={{
                type: BasicShadowMap,
            }} dpr={[1, 1]}>
                <DPRHandler isDesktopSize={isDesktopSize}/>
                <Camera isDesktopSize={isDesktopSize}/>
                <Scenery isDesktopSize={isDesktopSize} htmlContainerRef={htmlContainerRef}/>
                <Characters/>
            </Canvas>
        </StyledContainer>
    )
}

import {proxy, useSnapshot} from "valtio";

export enum CurrentStep {
    START,
    FIRST,
    SECOND,
    THIRD,
    FOURTH,
}

export const demoUIProxy = proxy({
    muteVideo: () => {},
    unmuteVideo: () => {},
    showVideoModal: false,
})

export const useShowVideoModal = () => {
    return useSnapshot(demoUIProxy).showVideoModal
}

export const setShowVideoModal = (show: boolean) => {
    demoUIProxy.showVideoModal = show
}

export const setMuteUnmuteFunctions = (mute: any, unmute: any) => {
    demoUIProxy.muteVideo = mute
    demoUIProxy.unmuteVideo = unmute
}

export const demoStateProxy = proxy({
    nextStep: CurrentStep.START,
    currentStep: CurrentStep.FIRST,
    firstStepProgress: 0,
    secondStepProgress: 0,
    thirdStepProgress: 0,
    fourthStepProgress: 0,
})

export const useNextStep = () => {
    return useSnapshot(demoStateProxy).nextStep
}

export const useCurrentStep = () => {
    return useSnapshot(demoStateProxy).currentStep
}

const limit = 100

export const setDemoProgress = (progress: number, secondStepProgress: number, thirdStepProgress: number, fourthStepProgress: number) => {
    demoStateProxy.firstStepProgress = progress
    demoStateProxy.secondStepProgress = secondStepProgress
    demoStateProxy.thirdStepProgress = thirdStepProgress
    demoStateProxy.fourthStepProgress = fourthStepProgress

    let currentStep = CurrentStep.FIRST
    let nextStep = CurrentStep.START

    if (fourthStepProgress > 20) {
        nextStep = CurrentStep.FOURTH
    } else if (thirdStepProgress > 10) {
        nextStep = CurrentStep.THIRD
    } else if (secondStepProgress > 10) {
        nextStep = CurrentStep.SECOND
    } else if (progress > 35) {
        nextStep = CurrentStep.FIRST
    } else {
        nextStep = CurrentStep.START
    }

    if (progress <= limit && secondStepProgress === 0) {
        currentStep = CurrentStep.FIRST
    } else if (secondStepProgress <= limit && thirdStepProgress === 0) {
        currentStep = CurrentStep.SECOND
    } else if (thirdStepProgress <= limit && fourthStepProgress === 0) {
        currentStep = CurrentStep.THIRD
    } else {
        currentStep = CurrentStep.FOURTH
    }

    demoStateProxy.currentStep = currentStep
    demoStateProxy.nextStep = nextStep

}

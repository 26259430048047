import styled, {css} from "styled-components";
import {MOBILE_BREAKPOINT} from "./responsive";

export const cssButtonBackgroundReset = css`
  border: 0;
  padding: 0;
  color: inherit;
  background: none;
  margin: 0;
`

export const cssButtonReset = css`
  ${cssButtonBackgroundReset};
  font: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  
  &:disabled {
    cursor: default;
  }
  
`

const cssNotClear = css`
  padding: 14px 20px;
  background-color: #ff4fce;

  &:hover,
  &:focus {
    background-color: #d73eac;
  }

`

const cssClear = css`
  padding: 14px;

  ${MOBILE_BREAKPOINT} {
    padding: 10px;
  }
  
  &:focus,
  &:hover {
    text-decoration: underline;
  }
  
`

export const StyledRoundedButton = styled.button<{
  clear?: boolean,
}>`
  ${cssButtonReset};
  padding: 14px 20px;
  ${props => props.clear ? cssClear : cssNotClear};
  color: white;
  border-radius: 24px;
  
  ${MOBILE_BREAKPOINT} {
    font-size: 0.9rem;
    padding: 10px 14px;
  }
`

import * as React from "react"
import styled from "styled-components";
import { StyledMaxWidth } from "../ui/layout";
import {cssZIndexTop, THEME} from "../ui/theme";
import {DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT} from "../ui/responsive";
import {TECH_CRUNCH_URL, THE_VERGE_URL, WIRED_URL} from "../data";

const StyledContainer = styled.div`
    background-color: white;
    padding: 60px 0 70px 0;
    margin-top: 25vh;
    position: relative;
    ${cssZIndexTop};
  
    ${MOBILE_BREAKPOINT} {
      padding: 30px 20px 30px 20px;
      background-color: rgba(255,255,255,0.9);
    }
  
`

const StyledContent = styled.div`
  
  ${DESKTOP_BREAKPOINT} {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: ${THEME.spacing.$6}px;
    padding: 0 40px;
  }
  
  ${MOBILE_BREAKPOINT} {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: ${THEME.spacing.$3}px;
  }
  
`

const StyledHeader = styled.header`
  text-align: center;
  margin-bottom: ${THEME.spacing.$5}px;
  
  ${MOBILE_BREAKPOINT} {
    margin-bottom: ${THEME.spacing.$4}px;
  }
  
  h4 {
    font-size: 1.25rem;
    text-transform: uppercase;
    
    ${MOBILE_BREAKPOINT} {
      font-size: 1.1rem;
    }
    
  }
  
`

const StyledTestimonial = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  text-align: center;
  
  q {
    font-size: 1.5rem;
    margin-bottom: ${THEME.spacing.$2}px;
    display: block;
    quotes: "“" "”" "‘" "’";

    ${MOBILE_BREAKPOINT} {
      font-size: 1.1rem;
      margin-bottom: ${THEME.spacing.$1b}px;
    }
    
    &::before {
      content: open-quote;
    }
    &::after {
      content: close-quote;
    }
    
  }
  
  &:hover,
  &:focus {
    
    h4 {
      text-decoration: underline;
    }
    
  }
  
`

export const Testimonials: React.FC = () => {
    return (
        <StyledContainer>
            <StyledMaxWidth>
                <StyledHeader>
                    <h4>As seen in</h4>
                </StyledHeader>
                <StyledContent>
                    <StyledTestimonial href={TECH_CRUNCH_URL} target={'_blank'}>
                        <q>
                            Skittish is what you'd get if you crossed Animal Crossing with Clubhouse.
                        </q>
                        <h4>
                            TechCrunch
                        </h4>
                    </StyledTestimonial>
                    <StyledTestimonial href={THE_VERGE_URL} target={'_blank'}>
                        <q>
                            A virtual event space built from the lessons of the pandemic.
                        </q>
                        <h4>
                            The Verge
                        </h4>
                    </StyledTestimonial>
                    <StyledTestimonial href={WIRED_URL} target={'_blank'}>
                        <q>
                            Online meetups are sad, but what if you were a cute animal?
                        </q>
                        <h4>
                            Wired
                        </h4>
                    </StyledTestimonial>
                </StyledContent>
            </StyledMaxWidth>
        </StyledContainer>
    )
}

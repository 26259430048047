import {useAnimations, useGLTF} from "@react-three/drei"
import * as React from "react"
import {Suspense, useCallback, useEffect, useMemo, useRef} from "react"
import {SkeletonUtils} from "three-stdlib";
import {AnimationAction, AnimationUtils, Object3D} from "three";
import {ANIMALS_DATA} from "../data/animals";
import {degToRad} from "three/src/math/MathUtils";

const recursiveMeshes = (child: any): any[] => {
    let meshes: any[] = []

    if (child.type === 'SkinnedMesh' || child.type === 'Mesh') {
        meshes.push(child)
    } else {
        if (child.children) {
            child.children.forEach((subChild: any) => {
                meshes = meshes.concat(recursiveMeshes(subChild))
            })
        }
    }

    return meshes
}

const getSkinnedMeshes = (scene: any): any[] => {

    let meshes: any[] = []

    scene.children.forEach((child: any) => {
        meshes = meshes.concat(recursiveMeshes(child))
    })

    return meshes
}

export const setShadows = (scene: any) => {
    const skinnedMeshes = getSkinnedMeshes(scene)
    skinnedMeshes.forEach((mesh: any) => {
        // eslint-disable-next-line no-param-reassign
        mesh.castShadow = true
        // eslint-disable-next-line no-param-reassign
        mesh.receiveShadow = true
    })
}

type ActionName = 'Idle' | 'Walk'
export type GLTFActions = Record<ActionName, AnimationAction>

const useHandleAnimation = (actions: GLTFActions, moving: boolean, timeScale: number) => {

    const currentAnimationRef = useRef<{
        key: string | null,
        animation: any,
        finished: boolean,
    }>({
        key: null,
        animation: null,
        finished: false,
    })

    const playAnimation = useCallback((animation: any, fadeInDuration: number, fadeDuration: number, key: string | null) => {
        const currentAnimation = currentAnimationRef.current
        if (currentAnimation.animation) {
            currentAnimation.animation.fadeOut(fadeDuration)
        } else {
            fadeInDuration = 0
        }
        animation
            .reset()
            .setEffectiveTimeScale(timeScale)
            .setEffectiveWeight(1)
            .fadeIn(fadeInDuration)
            .play();
        currentAnimation.animation = animation
        currentAnimation.key = key
        currentAnimation.finished = false
    }, [currentAnimationRef])

    useEffect(() => {

        if (moving) {
            playAnimation(actions.Walk, 250 / 1000, 250 / 1000, 'walk')
        } else {
            playAnimation(actions.Idle, 250 / 1000, 250 / 1000, 'idle')
        }

    }, [actions, moving, playAnimation])

}

const characterScale: [number, number, number] = [0.66, 0.66, 0.66]

const LOW_QUALITY_PATH = `_LOD3.gltf`
const HIGH_QUALITY_PATH = `_LOD1.gltf`

const AnimalAvatar: React.FC<{
    animal: string,
    moving: boolean,
    loadWalking: boolean,
    higherQuality?: boolean,
}> = ({animal, moving, loadWalking, higherQuality = false}) => {

    const idlePath = `/models/animals/${animal}_Idle.gltf`
    const walkPath = `/models/animals/${animal}_Walk.gltf`

    const groupRef = useRef<Object3D>(null!)
    const { scene } = useGLTF(`/models/animals/${animal}${higherQuality ? HIGH_QUALITY_PATH : LOW_QUALITY_PATH}`) as any
    const { animations: idleAnimations } = useGLTF(idlePath) as any
    const { animations: walkAnimations } = useGLTF(loadWalking ? walkPath : idlePath) as any

    const cloned = useMemo(() => {
        const clonedScene = SkeletonUtils.clone(scene)
        setShadows(clonedScene)
        return clonedScene
    }, [scene])

    const {
        idleEndFrame,
        timeScale
    } = useMemo(() => {
        const matchedAnimal = ANIMALS_DATA.find(animalData => {
            return animalData.key === animal
        })
        const {
            idleEndFrame = 0,
            timeScale = 1,
        } = matchedAnimal ?? {}
        return {
            idleEndFrame,
            timeScale: timeScale * 0.66,
        }
    }, [animal])

    const animations = useMemo(() => {
        let animations = []
        if (idleAnimations[0]) {
            const subIdleClip = AnimationUtils.subclip(idleAnimations[0], 'Idle', 0, idleEndFrame)
            animations.push(subIdleClip)
        }
        if (loadWalking) {
            animations.push(...walkAnimations)
        }
        return animations
    }, [idleAnimations, walkAnimations])

    // @ts-ignore
    const { actions } = useAnimations(animations, groupRef) as { actions: GLTFActions}

    useHandleAnimation(actions, moving, timeScale)

    return (
        <primitive scale={characterScale} object={cloned} dispose={null} ref={groupRef}/>
    )
}

export const Character: React.FC<{
    position: [number, number, number],
    rotation?: [number, number, number],
    animal: string,
    moving?: boolean,
    loadWalking?: boolean,
    higherQuality?: boolean,
}> = ({position, rotation, animal, moving = false, loadWalking = false, higherQuality = false}) => {
    return (
        <>
            <group position={position} rotation={rotation}>
                <Suspense fallback={null}>
                    <AnimalAvatar animal={animal} moving={moving} loadWalking={loadWalking} higherQuality={higherQuality}/>
                </Suspense>
            </group>
        </>
    )
}

import {useEffect, useState} from "react";
import {BREAKPOINTS} from "../ui/responsive";

export enum DesktopWidthType {
    NORMAL,
    SLIM,
    SKINNY,
    EXTRA_SKINNY,
}

export const useDesktopWidthType = () => {
    const [desktopWidthType, setDesktopWidthType] = useState(DesktopWidthType.NORMAL)

    useEffect(() => {

        const checkWindowSize = () => {
            const ratio = window.innerWidth / window.innerHeight

            if (ratio < 1.1) {
                setDesktopWidthType(DesktopWidthType.EXTRA_SKINNY)
            } else if (ratio < 1.25) {
                setDesktopWidthType(DesktopWidthType.SKINNY)
            } else if (ratio < 1.5) {
                setDesktopWidthType(DesktopWidthType.SLIM)
            } else {
                setDesktopWidthType(DesktopWidthType.NORMAL)
            }

        }

        checkWindowSize()

        window.addEventListener('resize', checkWindowSize)

        return () => {
            window.removeEventListener('resize', checkWindowSize)
        }

    }, [])

    return desktopWidthType

}

export const isDesktopSize = () => {
    if (typeof window !== 'undefined') {
        return window.innerWidth >= BREAKPOINTS.mobile + 1
    } else {
        return false
    }
}

export const useIsDesktopSize = () => {
    const [desktopSize, setDesktopSize] = useState(isDesktopSize())

    useEffect(() => {

        const checkWindowSize = () => {
            setDesktopSize(isDesktopSize())
        }

        checkWindowSize()

        window.addEventListener('resize', checkWindowSize)

        return () => {
            window.removeEventListener('resize', checkWindowSize)
        }

    }, [])

    return desktopSize
}
